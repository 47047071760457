import "./header.css";
import { ReactComponent as Svgrepo } from "./svg/svgrepo.svg";
import { ReactComponent as Code } from "./svg/code.svg";
import { ReactComponent as JS } from "./svg/js.svg";
import { ReactComponent as ReactSVG } from "./svg/react.svg";
import { ReactComponent as Git } from "./svg/git.svg";
import { ReactComponent as Html } from "./svg/html.svg";
import { ReactComponent as Time } from "./svg/time.svg";
import { ReactComponent as Web } from "./svg/web.svg";
import { ReactComponent as Heart } from "./svg/heart.svg";
import { ReactComponent as Cloud } from "./svg/cloud.svg";
import { ReactComponent as Js } from "./svg/js.svg";
import { ReactComponent as Jsx } from "./svg/jsx.svg";

function Header({ children }) {
  let arr = [];
  let qty = 15;

  for (let i = 0; i < qty; i++) {
    arr.push(i);
  }

  const layers = arr.map((i) => {
    return (
      <div key={i} className="animated-row">
        <div>
          <Code />
          <JS />
          <Jsx />
          <Svgrepo />

          <Git />
          <Html />
          <Js />
          <Web />
          <ReactSVG />
          <Heart />
          <Cloud />
        </div>

        <div>
          <Code />
          <JS />
          <Jsx />
          <Svgrepo />
          <Git />
          <Html />
          <Js />
          <ReactSVG />
          <Web />
          <Heart />
          <Cloud />
        </div>
      </div>
    );
  });

  let msgArray = [
    " ",
    "A Software Engineer",
    "Also known as an",
    "Front-end developer",
    "Back-end developer",
    "Full stack developer",
    "Or!",
    '"a coder"',
    " ",
  ];

  return (
    <>
      <section className="animated-section">
        {layers}
        {children}
      </section>

      <div className="landing-type">
        <div> React למצב</div>
      </div>
    </>
  );
}

export default Header;
