import { SiAdobexd } from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";

const data = [
  {
    id: 1,
    icon: <SiAdobexd />,
    title: "UI/UX פרויקטים",
    desc: "נבנה פרוייקטים קטנים, בכל פרויקט יהיה חלק נוסף בשפה",
  },
  {
    id: 2,
    icon: <RiReactjsLine />,
    title: "Frontend Development",
    desc: "נבנה אתרים עם עיצוב וממשק נוח למשתמש.",
  },
  {
    id: 3,
    icon: <FaServer />,
    title: "Backend Development",
    desc: "The security of your business/product is taken seriously right from the start of the project. I will make sure your website/app is secure from attacks.",
  },
  {
    id: 4,
    icon: <AiFillAppstore />,
    title: "App Development",
    desc: "נלמד  בניית אפליקציות למערכות הפעלה Androaid ו-IOS ו.",
  },
];

export default data;
