import Image1 from "../../assets/project1.png";
import Image2 from "../../assets/p1- useState.png";
import Image3 from "../../assets/project3.png";
import Image4 from "../../assets/project4.png.png";
import Image5 from "../../assets/project5.png.png";
import Image6 from "../../assets/project6.jpg";
import Image7 from "../../assets/project7.jpg";
import Image8 from "../../assets/project8.jpg";
import Image9 from "../../assets/project9.jpg";

const data = [
  {
    id: 1,
    category: "Components",
    image: Image1,
    title: "Steps",
    desc: "state & function - ניצור כפתורים שישנו את צבע העמוד",
    demo: "http://rp-changecolor.more-ways.co.il/",
    github: "https://github.com/eylommaayan/changeColer",
  },
  {
    id: 2,
    category: "Components",
    image: Image2,
    title: "1- useState - esignature",
    desc: "בפרויקט זה נלמד ליצור תנאים עם js ושינוי מצבים עם useStae",
    demo: "https://p-usestae-1.more-ways.co.il/",
    github: "https://github.com/eylommaayan/React-usestate-p1",
  },
  {
    id: 3,
    category: "Components",
    image: Image3,
    title: "2-useState - counter & Dtae",
    desc: "ניצור באמצעות state ספירת מספרים ועם הכמות מספרים נדלג בין תאריכים",
    demo: "http://rp-usestate-c.more-ways.co.il/",
    github: "https://github.com/eylommaayan/React-useState-counter",
  },
  {
    id: 4,
    category: "Components",
    image: Image4,
    title: "3-useState - Steps ",
    desc: "עם useState - כפתורים, שינוי מצב, וגם הפעלת כפתור לסגירת המראה",
    demo: "https://rp1-steps-usestate.more-ways.co.il/",
    github: "https://github.com/eylommaayan/useStae-steps",
  },
  {
    id: 5,
    category: "Components",
    image: Image5,
    title: "4- useState - LikePhoto",
    desc: "בפרויקט זה ניצור אפשרות לעשות לייק לתמונה או לא בלחיצה עם useState",
    demo: "http://rp-usestate-likephoto.more-ways.co.il/",
    github: "https://github.com/eylommaayan/usestate-likephoto",
  },
  {
    id: 6,
    category: "frontend",
    image: Image6,
    title: "Project Title Six (Frontend)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 7,
    category: "frontend",
    image: Image7,
    title: "Project Title Seven (Frontend)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 8,
    category: "backend",
    image: Image8,
    title: "Project Title Eight (Backend)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
  {
    id: 9,
    category: "frontend",
    image: Image9,
    title: "Project Title Nine (Frontend)",
    desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    demo: "http://egatortutorials.com",
    github: "https://github.com/egattor",
  },
];

export default data;
